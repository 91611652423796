import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';
import React from 'react';
import { createTheme } from '@mui/material';

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return ( <RouterLink ref={ref} to={href} {...other} /> );
});

export const token = {
  size: {
    height: {
      rowDisplay: "40px"
    }
  },
  font: {
    size: {
      body: "1rem",
      small: "0.8rem",

    }
  },
  spacing: {
    s100: "2px",
    s200: "4px",
    s300: "8px",
    s400: "16px",
  },
  color: {
    text: {
      primary: "rgba(255,255,255,0.87)",
      primaryInverted: "#ff3434",
    },
    border: {
      inactive: "#fff"
    }
  }
}

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#901010',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#121212',
      paper: '#2a2c2c',
    },
    text: {
      primary: 'rgba(255,255,255,0.87)',
      secondary: 'rgba(255,255,255,0.6)',
      disabled: 'rgba(220,220,220,0.38)',
    },
    error: {
      main: '#d3a02f',
    },
  },
});